<template>
  <div class="app-container v">
    <div class="head-container">
      <quick-select v-model="query.disEntId" url="api/distributor" placeholder="经销商" clearable filterable @change="toQuery" value-field="enterpriseId" class="filter-item" style="width: 240px;" />
      <!-- <el-date-picker v-model="query.dateRange" type="daterange" class="filter-item" style="width: 220px;" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="searchDateRangeOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" @change="toQuery" /> -->
      <el-button size="mini" class="filter-item" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <el-button type="primary" class="filter-item" icon="el-icon-plus" @click="edit(null)">新建限购</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>
    <el-table v-loading="loading" row-key="id" :data="data" height="200px">
      <el-table-column min-width="200" label="限购名称" prop="name" />
      <el-table-column label="限购经销商" prop="disName" />
      <el-table-column label="开始时间" :formatter="r => { return new Date(r.begTime).format(); }" />
      <el-table-column label="结束时间" :formatter="r => { return new Date(r.endTime).format(); }" />
      <el-table-column label="状态" width="120">
        <template slot-scope="scope">
          <dot :type="status[scope.row.status].type" same>{{status[scope.row.status].label}}</dot>
        </template>
      </el-table-column>
      <el-table-column width="100" fixed="right">
        <div class="row-commands" slot-scope="scope">
          <!-- <el-button size="mini" type="text" @click="edit(scope.row)" v-if="scope.row.status === 0">编辑</el-button> -->

          <template v-if="scope.row.status === 0">
            <el-button size="mini" type="text" @click="edit(scope.row)">编辑</el-button>
            <el-popover :ref="scope.row.id" placement="top" width="180">
              <p>确定删除该限购吗？</p>
              <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
                <el-button :loading="delLoading" type="primary" size="mini" @click="subDelete(scope.row.id)">确定</el-button>
              </div>
              <el-button slot="reference" class="danger" type="text" size="mini">删除</el-button>
            </el-popover>
          </template>

          <el-button size="mini" type="text" @click="edit(scope.row)" v-else>查看</el-button>
        </div>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />

    <e-form ref="form" />
  </div>
</template>

<script>
import { del } from "@/api/goodsLimit";
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import eForm from "./form";

export default {
  components: { eForm },
  mixins: [initData],
  data() {
    return {
      downloadLoading: false,
      delLoading: false,
      status: [
        {
          type: "gray",
          label: "编辑中",
        },
        {
          type: "success",
          label: "已生效",
        },
        {
          type: "danger",
          label: "已取消",
        },
      ],
      searchDateRangeOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      query: {
        disEntId: null,
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/goodsLimit";
      let query = JSON.parse(JSON.stringify(this.query));
      // if (query.dateRange && query.dateRange.length === 2) {
      //   query.beginTime = query.dateRange[0];
      //   query.endTime = query.dateRange[1];
      // }
      // delete query.dateRange;
      this.params = Object.assign({sort: "createAt,desc"}, query);
      return true;
    },
    subDelete(id) {
      this.delLoading = true;
      del(id)
        .then((res) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.dleChangePage();
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500,
          });
        })
        .catch((err) => {
          this.delLoading = false;
          this.$refs[id].doClose();
        });
    },
    edit(data) {
      this.$refs.form && this.$refs.form.resetForm(data);
    },
  },
};
</script>