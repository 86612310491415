import request from '@/utils/request'

export function getById(id) {
  return request({
    url: "api/goodsLimit/" + id,
    method: "get"
  });
}


export function add(data) {
  return request({
    url: "api/goodsLimit",
    method: "post",
    data
  });
}

export function edit(data) {
  return request({
    url: "api/goodsLimit",
    method: "put",
    data
  });
}


export function enable(id, enable) {
  return request({
    url: "api/goodsLimit/enable",
    method: "put",
    data: {
      id,
      status: enable ? 1 : 2
    }
  });
}


export function del(id) {
  return request({
    url: "api/goodsLimit/" + id,
    method: "delete"
  });
}