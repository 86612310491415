<template>
  <el-dialog :visible.sync="visible" :title="title" append-to-body :close-on-click-modal="false" @closed="form = null" width="840px">
    <template v-if="form">
      <template v-if="editable">
        <el-form ref="form" :model="form" :rules="rules" label-position="top">
          <div class="h e">
            <div class="flex">
              <el-form-item label="限购名称" prop="name">
                <el-input v-model="form.name" :maxlength="50" />
              </el-form-item>

              <el-form-item label="限购经销商" prop="disEntId">
                <quick-select v-model="form.disEntId" url="api/distributor" placeholder="请选择经销商" filterable value-field="enterpriseId" style="width: 100%;" />
              </el-form-item>
            </div>
            <el-form-item label="限购时间" prop="dateRange" style="width: 320px; margin-left: 20px;">
              <el-date-picker v-model="form.dateRange" type="daterange" style="width: 100%;" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="dateRangeOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" />
            </el-form-item>
          </div>

          <el-form-item label="限购详情" prop="sublist">
            <div class="h">
              <sku-selector request-url="api/goods/sku" button-text="添加限购商品" :exclude-keys="excludeGoods" @submit="handleAddGoods" />
            </div>

            <el-table :data="form.sublist" border class="gap-1x">
              <el-table-column prop="goods.name" label="商品名称" min-width="240" />
              <el-table-column prop="goods.specs" label="规格" min-width="150" :formatter="$goodsSpecConvert" />
              <el-table-column label="限购数量" width="120">
                <template slot-scope="scope">
                  <el-input-number v-model="scope.row.limitCount" :min="1" :max="999999" :step="1" :precision="0" size="mini" controls-position="right" @change="$checkNaN(scope.row, 'limitCount', 1)" style="width: 100%;" />
                </template>
              </el-table-column>
              <el-table-column width="50">
                <div class="row-commands" slot-scope="scope">
                  <el-button type="text" size="mini" class="danger" @click="handleRemoveGoods(scope.row)">删除</el-button>
                </div>
              </el-table-column>
            </el-table>
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button type="text" @click="visible = false">取消</el-button>
          <el-button :loading="saving" :disabled="submiting" type="primary" @click="doSave">保存</el-button>
          <el-button :loading="submiting" :disabled="saving" type="danger" @click="doEnable" v-if="form.id">保存并生效</el-button>
        </div>
      </template>
      <template v-else>
        <el-form :model="form" label-position="right" label-width="90px" label-suffix="：">
          <form-info-item label="限购名称">{{form.name}}</form-info-item>
          <form-info-item label="限购经销商">{{form.disName}}</form-info-item>

          <form-info-item label="限购时间">{{new Date(form.begTime).format("yyyy/MM/dd")}} 至 {{new Date(form.endTime).format("yyyy/MM/dd")}}</form-info-item>

          <form-info-item label="　限购详情" inline>
            <el-table :data="form.sublist" border>
              <el-table-column prop="goods.name" label="商品名称" min-width="240" />
              <el-table-column prop="goods.specs" label="规格" min-width="150" :formatter="$goodsSpecConvert" />
              <el-table-column prop="limitCount" label="限购数量" width="120" />
            </el-table>
          </form-info-item>

          <form-info-item label="状态"><dot :type="status[form.status].type" same>{{status[form.status].label}}</dot></form-info-item>
        </el-form>

        <div slot="footer" v-if="form.status === 1">
          <el-button :loading="submiting" type="danger" @click="doDisable">取消限购</el-button>
        </div>
      </template>
    </template>
  </el-dialog>
</template>

<script>
import { getById, add, edit, enable as limitEnable } from "@/api/goodsLimit";
import skuSelector from "@/views/assembly/skuSelect";

export default {
  components: { skuSelector },
  data() {
    return {
      visible: false,
      loading: false,
      saving: false,
      submiting: false,
      form: null,
      rules: {
        name: [{ required: true, message: "请输入限购名称" }],
        // disEntId: [{ required: true, message: "请选择要进行限购的经销商" }],
        dateRange: [{ required: true, message: "请选择限制时间" }],
        sublist: [
          { required: true, message: "请设置要限购的商品和数量" },
          {
            type: "array",
            min: 1,
            message: "请设置要限购的商品和数量",
          },
        ],
      },
      status: [
        {
          type: "gray",
          label: "编辑中",
        },
        {
          type: "success",
          label: "已生效",
        },
        {
          type: "danger",
          label: "已取消",
        },
      ],
      dateRangeOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              let d = new Date();
              const start = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0);
              const end = new Date(d.getFullYear(), d.getMonth(), d.getDate() + 6, 23, 59, 59);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              let d = new Date();
              const start = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0);
              const end = new Date(d.getFullYear(), d.getMonth() + 1, d.getDate() - 1, 23, 59, 59);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              let d = new Date();
              const start = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0);
              const end = new Date(d.getFullYear(), d.getMonth() + 3, d.getDate() - 1, 23, 59, 59);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  computed: {
    title() {
      let s = "查看";
      if (this.form && this.form.status === 0) {
        s = this.form.id ? "编辑" : "新增";
      }
      return s + "商品限购";
    },
    excludeGoods() {
      if (this.form) {
        return (this.form.sublist || []).map((o) => o.goodsId);
      }
      return null;
    },
    editable() {
      return this.form && this.form.status === 0;
    },
  },
  methods: {
    handleAddGoods(list) {
      (list || []).forEach((o) => {
        this.form.sublist.push({
          goodsId: o.id,
          goodsType: "sku",
          goods: o,
          limitCount: 100,
        });
      });
    },
    handleRemoveGoods(item) {
      let inx = this.form.sublist.indexOf(item);
      if (inx > -1) this.form.sublist.splice(inx, 1);
    },

    doSave(callback) {
      this.$refs.form &&
        this.$refs.form.validate().then(() => {
          this.form.begTime = this.form.dateRange[0];
          this.form.endTime = this.form.dateRange[1];
          this.saving = true;
          (this.form.id ? edit : add)(this.form)
            .then((res) => {
              if (typeof callback === "function") {
                callback();
              } else {
                this.$emit("设置限购成功");
                this.$parent.init();
                if (res) {
                  this.resetForm(res);
                }
              }
            })
            .finally(() => {
              this.saving = false;
            });
        });
    },

    doEnable() {
      if (this.form && this.form.id) {
        this.$confirm(`您确定要使当前限购生效吗？`, "操作提示", {
          type: "warning",
        }).then(() => {
          this.doSave(() => {
            this.submiting = true;
            limitEnable(this.form.id, true)
              .then((res) => {
                this.$message.success(`商品限购已经生效`);
                this.$parent.init();
                this.visible = false;
              })
              .finally(() => {
                this.submiting = false;
              });
          });
        });
      }
    },

    doDisable() {
      if (this.form && this.form.id) {
        this.$confirm(`您确定要取消当前限购吗？`, "操作提示", {
          type: "warning",
        }).then(() => {
          this.submiting = true;
          limitEnable(this.form.id, false)
            .then((res) => {
              this.$message.success(`取消商品限购成功`);
              this.$parent.init();
              this.visible = false;
            })
            .finally(() => {
              this.submiting = false;
            });
        });
      }
    },

    resetForm(data) {
      if (data) {
        this.loading = true;
        getById(data.id)
          .then((res) => {
            res.dateRange = [res.begTime, res.endTime];
            this.form = res;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.form = {
          name: "",
          disEntId: null,
          dateRange: null,
          status: 0,
          sublist: [],
        };
      }
      this.visible = true;
    },
  },
};
</script>